import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import BlogCard from 'components/blog-card'

function AllBlogs({ posts }) {
  return (
    <Section>
      <Container className="content is-widescreen">
        <Columns className="is-centered">
          <Columns.Column className="is-12-desktop is-12-tablet">
            <Columns className="is-centered">
              {posts.edges.map(({ node: article }, index) =>
                index % 7 === 0 ? (
                  <Columns.Column className="is-12" key={article.id}>
                    <BlogCard
                      jumbo
                      hasBorderTop={index > 0}
                      article={article}
                    />
                  </Columns.Column>
                ) : (
                  <Columns.Column
                    className="is-4-desktop is-4-tablet is-multiline"
                    key={article.id}
                  >
                    <BlogCard article={article} />
                  </Columns.Column>
                )
              )}
            </Columns>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
AllBlogs.propTypes = {
  posts: PropTypes.object,
}

export default AllBlogs
