import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'gatsby'

function BlogNav({ data }) {
  return (
    <div className="tabs is-medium is-centered">
      <ul>
        <li>
          <Link to="/blog">Latest</Link>
        </li>
        <li
          className={
            data.datoCmsCategory.slug === 'get-results-with-mobile'
              ? 'is-active'
              : ''
          }
        >
          <Link to="/blog/categories/get-results-with-mobile">Strategy</Link>
        </li>
        <li
          className={
            data.datoCmsCategory.slug === 'improve-your-customer-experience'
              ? 'is-active'
              : ''
          }
        >
          <Link to="/blog/categories/improve-your-customer-experience">
            Design
          </Link>
        </li>
        <li
          className={
            data.datoCmsCategory.slug === 'understand-the-tech'
              ? 'is-active'
              : ''
          }
        >
          <Link to="/blog/categories/understand-the-tech">Technology</Link>
        </li>
        <li
          className={
            data.datoCmsCategory.slug === 'get-to-know-pocketworks'
              ? 'is-active'
              : ''
          }
        >
          <Link to="/blog/categories/get-to-know-pocketworks">PocketLife</Link>
        </li>
      </ul>
    </div>
  )
}

BlogNav.propTypes = {
  data: PropTypes.object,
}

export default BlogNav
