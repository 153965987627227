import PropTypes from 'prop-types'
import React from 'react'
import { Container, Hero } from 'react-bulma-components'

function BlogHero({ data }) {
  return (
    <Hero className="is-small is-hidden">
      <Hero.Body>
        <Container>
          <div className="columns content is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1
                className="title is-size-1-desktop is-size-3-mobile is-size-2-tablet"
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsCategory.description,
                }}
              />
            </div>
            <div className="column is-4">
              <img
                src="https://www.datocms-assets.com/20955/1579120516-blog.png?fm=jpg&w=450"
                alt=""
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

BlogHero.propTypes = {
  data: PropTypes.object,
}

export default BlogHero
