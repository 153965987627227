import PropTypes from 'prop-types'
import React from 'react'

// Componnets
import Layout from 'components/layout'
import SiteNav from 'components/navbar'
import AllBlogs from 'components/allBlogs'

// Page Components
import BlogNav from './components/blog-nav/BlogNav'
import BlogHero from './components/blog-hero/BlogHero'

function BlogCategory({ data }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <BlogNav data={data} />
      <BlogHero data={data} />
      <AllBlogs posts={data.allDatoCmsArticle} />
    </Layout>
  )
}

BlogCategory.propTypes = {
  data: PropTypes.object,
}

export default BlogCategory
